import React, { Dispatch, SetStateAction, useState } from "react";
import styles from "./navigation-menu-burger.module.scss";
import Link from "next/link";
import Button from "../common/button/button";

interface NavigationBurgerProps {
	showBurgerMenu: boolean;
	setShowBurgetMenu: Dispatch<SetStateAction<boolean>>;
}

function NavigationBurger({
	showBurgerMenu,
	setShowBurgetMenu,
}: NavigationBurgerProps): JSX.Element {
	const [showOptions, setShowOptions] = useState(false);

	const handleMouseEnter = () => {
		setShowOptions(true);
	};

	const handleMouseLeave = () => {
		setShowOptions(false);
	};

	const menuOptions = [
		{
			label: "About Us",
			link: "/about",
			class: !showBurgerMenu
				? styles.linkAnimation
				: styles.linkAnimationActive,
		},
		{
			label: "Resources",
			link: "/resources",
			class: !showBurgerMenu
				? styles.linkAnimation1
				: styles.linkAnimationActive1,
		},
		{
			label: "Reviews",
			link: "/reviews",
			class: !showBurgerMenu
				? styles.linkAnimation2
				: styles.linkAnimationActive2,
		},
		{
			label: "State of the Industry Report",
			link: "/liquidationindustryreport",
			class: !showBurgerMenu
				? styles.linkAnimation3
				: styles.linkAnimationActive3,
		},
		{
			label: "FAQ",
			link: "/faq",
			class: !showBurgerMenu
				? styles.linkAnimation4
				: styles.linkAnimationActive4,
		},
		{
			label: "Contact Us",
			link: "/contact",
			class: !showBurgerMenu
				? styles.linkAnimation5
				: styles.linkAnimationActive5,
		},
		{
			label: "Truckload Inventory",
			link: "/inventory",
			class: !showBurgerMenu
				? styles.linkAnimation6
				: styles.linkAnimationActive6,
		},
		{
			label: "Truckloads by State",
			link: "/inventory/state",
			class: !showBurgerMenu
				? styles.linkAnimation7
				: styles.linkAnimationActive7,
		},
		{
			label: "Truckload Categories",
			link: "/inventory/category",
			class: !showBurgerMenu
				? styles.linkAnimation8
				: styles.linkAnimationActive8,
		},
		{
			label: "Pallet Inventory",
			link: "https://pallets.buylowwarehouse.com",
			class: !showBurgerMenu
				? styles.linkAnimation9
				: styles.linkAnimationActive9,
		},
	];

	return (
		<div className={styles.wrapper}>
			<div
				className={
					styles.burgerMenuContainer +
					" " +
					(showBurgerMenu ? styles.is_active : "")
				}
			>
				<div className={styles.box} onClick={() => setShowBurgetMenu(false)}>
					<div className={styles.menuWrapper}>
						{menuOptions.map((option) => {
							return (
								<div key={option.link} className={option.class}>
									<Link href={option.link}>
										<a>
											<div className={styles.itemBurger}>{option.label}</div>
										</a>
									</Link>
								</div>
							);
						})}
					</div>
					<div
						className={`${
							!showBurgerMenu
								? styles.linkAnimationButton
								: styles.linkAnimationActiveButton
						}`}
					>
						<Link href="/inventory">
							<a>Shop Truckloads Now!</a>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NavigationBurger;
